 import type { ExternalClient } from '@pigello/pigello-matrix';


import { useGetInstance, useGetList } from '@/requests/hooks';

import type {
  useGetGenericInstanceProps,
  useGetGenericListProps,
} from '@/requests/types';


export const useGetExternalClient = (
  options: useGetGenericInstanceProps<ExternalClient>
) => {
  return useGetInstance<ExternalClient>({
    ...options,
    modelName: 'externalclient'
  });
};

export const useGetExternalClientList = (
  options: useGetGenericListProps<ExternalClient>
) => {
  return useGetList<ExternalClient>({
    ...options,
    modelName: 'externalclient'
  });
};
