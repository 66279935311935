import type {
  FieldValue,
  IntegrationManifestService,
} from '@pigello/pigello-matrix';

export function getRequiredActivationFields(
  service: IntegrationManifestService
) {
  const requiredFields: string[] = [];

  for (const [name, settings] of Object.entries(service.activation_variables)) {
    if (settings.allow_null === false && settings.default_value === null) {
      requiredFields.push(name);
    }
  }

  return requiredFields;
}

export function removeNonRequiredActivationFields(
  activationVariables: FieldValue<Record<string, unknown>>,
  requiredFields: string[]
) {
  const cleanActivationCopy = { ...activationVariables };
  for (const field in activationVariables) {
    if (!requiredFields.includes(field)) {
      activationVariables[field] = undefined;
    }
  }
  return cleanActivationCopy;
}

export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(
              dirtyFields[key] as DirtyFields,
              values[key] as Values
            )
          : values[key],
    };
  }, {});

  return dirtyValues;
}

type Integration =
  | 'SCRIVE'
  | 'ECO_POST'
  | 'SIE4'
  | 'Gl07'
  | 'Gl02'
  | 'FORTNOX'
  | 'AUTOGIRO'
  | 'VISMA_EEKONOMI'
  | 'PS_FINANCE';

type IntegrationIdMapType = {
  [applicationId: string]: {
    [serviceId: string]: Integration;
  };
};
export const IntegrationIdMap: IntegrationIdMapType = {
  // Scrive
  '4c52aeff-a913-407d-9f4b-b6087b49490b': {
    'acd93c35-17c9-5cb7-93c7-d2f9a998344e': 'SCRIVE',
  },
  'ff7c49c6-9dee-4bb1-833f-dcd00c589207': {
    '58a1ef2e-8738-5bb7-a4e0-72a492f9e8db': 'SIE4',
    '58edb76d-fa92-5e8a-a7c0-4ad1ea6bff18': 'Gl02',
    'd88f61f4-37ac-5915-bd70-d358684382f6': 'Gl07',
  },
  '179cc7f5-9491-4e3c-8108-91f7e592a683': {
    'bfbc5035-2fcf-5aad-9863-1dad414411ee': 'FORTNOX',
  },
  '4ab874a5-b540-495c-bcc2-2ac7b4d3268d': {
    '464cf1e8-320d-521d-a393-8073c59995ad': 'VISMA_EEKONOMI',
  },
  '8a9f2b67-cdce-4e56-98be-31a152c0d9cb': {
    '9aeb3ef9-2ae8-5a85-8ff3-0d68ca9341aa': 'AUTOGIRO',
  },
  'f74d684c-6b20-4f9e-b07c-9fed2692be2d': {
    '2c67404f-6ad2-5e99-8afe-14afc37fc26f': 'PS_FINANCE',
  },
} as const;

type IIntegrationServiceDataMap = Partial<{
  [K in Integration]: {
    serviceId: string;
    applicationId: string;
  };
}>;

export const IntegrationServiceDataMap: IIntegrationServiceDataMap = {
  SCRIVE: {
    applicationId: '4c52aeff-a913-407d-9f4b-b6087b49490b',
    serviceId: 'acd93c35-17c9-5cb7-93c7-d2f9a998344e',
  },
  FORTNOX: {
    applicationId: '179cc7f5-9491-4e3c-8108-91f7e592a683',
    serviceId: 'bfbc5035-2fcf-5aad-9863-1dad414411ee',
  },
  VISMA_EEKONOMI: {
    applicationId: '4ab874a5-b540-495c-bcc2-2ac7b4d3268d',
    serviceId: '464cf1e8-320d-521d-a393-8073c59995ad',
  },
  AUTOGIRO: {
    applicationId: '8a9f2b67-cdce-4e56-98be-31a152c0d9cb',
    serviceId: '9aeb3ef9-2ae8-5a85-8ff3-0d68ca9341aa',
  },
  SIE4: {
    applicationId: 'ff7c49c6-9dee-4bb1-833f-dcd00c589207',
    serviceId: '58a1ef2e-8738-5bb7-a4e0-72a492f9e8db',
  },
  Gl02: {
    applicationId: 'ff7c49c6-9dee-4bb1-833f-dcd00c589207',
    serviceId: '58edb76d-fa92-5e8a-a7c0-4ad1ea6bff18',
  },
  Gl07: {
    applicationId: 'ff7c49c6-9dee-4bb1-833f-dcd00c589207',
    serviceId: 'd88f61f4-37ac-5915-bd70-d358684382f6',
  },
  PS_FINANCE: {
    applicationId: 'f74d684c-6b20-4f9e-b07c-9fed2692be2d',
    serviceId: '2c67404f-6ad2-5e99-8afe-14afc37fc26f',
  },
} as const;

export const ApplicationIdToReadable: Record<string, string> = {
  '179cc7f5-9491-4e3c-8108-91f7e592a683': 'Fortnox',
  'ff7c49c6-9dee-4bb1-833f-dcd00c589207': 'SFTP',
  '4c52aeff-a913-407d-9f4b-b6087b49490b': 'Scrive',
  '8a9f2b67-cdce-4e56-98be-31a152c0d9cb': 'Autogiro',
  '4ab874a5-b540-495c-bcc2-2ac7b4d3268d': 'Visma',
  'f74d684c-6b20-4f9e-b07c-9fed2692be2d': 'PS Finance',
} as const;
