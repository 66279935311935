// utils/notificationUtils.ts
import { getConfig } from '@/lib/get-config';
import { get } from '@/requests/api/get';
import type {
  BaseInstance,
  InferMonitoring,
  ModelName,
  Notification,
} from '@pigello/pigello-matrix';

type Event = 'monitoring' | 'revenue' | 'accounts' | 'contracts' | undefined;

interface RedirectResult {
  redirectUrl?: string;
}

export async function getRedirectUrl(id: string): Promise<RedirectResult> {
  // Fetch the notification
  const notification = await get<Notification>({
    id,
    modelName: 'notification',
  });
  const modelName = notification.contentType?.id.split('.')[1] as ModelName;
  const config = getConfig(modelName, true);
  const parentConfig = config?.parentConfig
    ? getConfig(config.parentConfig)
    : undefined;
  const eventIdentifier = notification.eventIdentifier?.split('.')[0] as Event;
  const event = notification.eventIdentifier?.split('.')[2];

  if (!eventIdentifier) {
    return { redirectUrl: undefined };
  }

  if (!config) {
    return { redirectUrl: undefined };
  }

  switch (eventIdentifier) {
    case 'monitoring': {
      const monitoredInstance = await hasMonitoredInstance(id, modelName);
      if (monitoredInstance?.mtParentInstance?.id && parentConfig) {
        return {
          redirectUrl: `/detail/${parentConfig.modelName}/${monitoredInstance.mtParentInstance.id}/monitoring`,
        };
      }
      return {
        redirectUrl: `/dashboard/inbox/pending-approvals/${config.modelName}`,
      };
    }

    case 'revenue':
      return {
        redirectUrl: `/dashboard/inbox/retroactivity-proposals`,
      };

    case 'contracts':
      return {
        redirectUrl: `/detail/${modelName}/${notification.objectId}`,
      };

    case 'accounts': {
      if (
        modelName === 'subtenant' ||
        event === 'userinvited' ||
        event === 'userhotploginlinkcreated'
      ) {
        return { redirectUrl: undefined };
      }
      return {
        redirectUrl: `/detail/${modelName}/${notification.objectId}`,
      };
    }

    default:
      return { redirectUrl: undefined };
  }
}

async function hasMonitoredInstance(
  id: string,
  modelName: ModelName
): Promise<InferMonitoring<BaseInstance> | undefined> {
  try {
    const monitoredInstance = await get<InferMonitoring<BaseInstance>>({
      id: id,
      modelName,
    });
    return monitoredInstance;
  } catch {
    return undefined;
  }
}
