 import type { Notification } from '@pigello/pigello-matrix';


import { useGetInstance, useGetList } from '@/requests/hooks';

import type {
  useGetGenericInstanceProps,
  useGetGenericListProps,
} from '@/requests/types';


export const useGetNotification = (
  options: useGetGenericInstanceProps<Notification>
) => {
  return useGetInstance<Notification>({
    ...options,
    modelName: 'notification'
  });
};

export const useGetNotificationList = (
  options: useGetGenericListProps<Notification>
) => {
  return useGetList<Notification>({
    ...options,
    modelName: 'notification'
  });
};
