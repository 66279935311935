import { Icons } from '@/components/icons';
import { Button, buttonVariants } from '@/components/ui/button';
import { SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useGetNotificationList } from '@/config/communication/notification/client';
import useBulk from '@/hooks/useBulk';
import { useToast } from '@/hooks/useToast';
import { Urls } from '@/lib/urls';
import { BellSnoozeIcon } from '@heroicons/react/16/solid';
import { NotificationConfig } from '@pigello/pigello-matrix';
import { keepPreviousData } from '@tanstack/react-query';
import Link from 'next/link';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { toast } from 'sonner';
import { Notification as NotificationComp } from './notification';

export function NotificationSheetContent({
  userId,
  setSheetOpen,
}: {
  userId?: string;
  setSheetOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [tab, setTab] = useState<string>('all');
  const { addToast } = useToast();
  const [amount, setAmount] = useState(10);
  const [unreadAmount, setUnreadAmount] = useState(10);
  const [readAmount, setReadAmount] = useState(10);

  const bulk = useBulk({ modelName: NotificationConfig.modelName });

  const {
    data: notifications,
    isLoading,
    isFetching,
  } = useGetNotificationList({
    queryParams: {
      filters: {
        ...(tab === 'read' && { read: { noop: true } }),
        ...(tab === 'unread' && { read: { noop: false } }),
        ...(userId && {
          sent_to_organization_user: {
            noop: userId,
          },
        }),
      },
      page: 1,
      ...(tab === 'all' && { pageSize: amount }),
      ...(tab === 'read' && { pageSize: readAmount }),
      ...(tab === 'unread' && { pageSize: unreadAmount }),
      order: ['-createdAt'],
    },
    placeholderData: keepPreviousData,
    enabled: !!userId,
  });

  const markAllAsRead = async () => {
    if (!bulk) return;

    if (
      (notifications?.list && notifications.list.length === 0) ||
      !notifications?.list
    ) {
      return;
    }

    const instancesToSave = notifications.list
      .filter((n) => !n.read)
      .map((n) => ({ id: n.id, read: true }));

    if (instancesToSave.length === 0) {
      toast.info('Du har inga olästa notifikationer');
      return;
    }

    const res = await bulk.save({ instances: instancesToSave });

    if (!res || res?.error) {
      addToast({
        type: 'error',
        title: 'Misslyckades med att markera alla som lästa',
      });
    } else {
      addToast({
        type: 'success',
        title: 'Alla notiser har markerats som lästa',
      });
    }

    bulk.reset();
  };

  return (
    <>
      <SheetHeader className='flex flex-row items-center justify-between border-b px-4 pb-4'>
        <SheetTitle className='flex w-full items-center space-x-2'>
          <div className='mr-auto'>Notifikationer</div>
          <Button
            disabled={bulk?.loading}
            onClick={markAllAsRead}
            variant={'outline'}
            size={'sm'}
          >
            Markera alla som lästa
          </Button>
          <Link
            prefetch={false}
            href={Urls.settings.profile.notificationsettings}
            className={buttonVariants({
              variant: 'outline',
              size: 'sm',
            })}
          >
            Inställningar
          </Link>
        </SheetTitle>
      </SheetHeader>
      <Tabs value={tab} onValueChange={(e) => setTab(e)} defaultValue='all'>
        <TabsList className='mx-4'>
          <TabsTrigger value='all'>Alla</TabsTrigger>
          <TabsTrigger value='read'>Lästa</TabsTrigger>
          <TabsTrigger value='unread'>Olästa</TabsTrigger>
        </TabsList>
        <TabsContent
          className='grid max-h-[min(100vh,calc(var(--window-keeper-height)-175px))] w-full overflow-y-auto px-4'
          value='all'
        >
          {!isLoading &&
            notifications?.list?.map((notification) => (
              <NotificationComp
                key={notification.id}
                notification={notification}
                setSheetOpen={setSheetOpen}
              />
            ))}
          {!isLoading && !isFetching && notifications?.list.length === 0 && (
            <div className='flex flex-col items-center gap-2'>
              <BellSnoozeIcon className='size-16' />
              <p className='text-center font-medium'>
                Du har inga notifikationer. Passa på att ta en rast och sträck
                lite på benen! :)
              </p>
            </div>
          )}
          {isLoading && (
            <div className='grid gap-2'>
              {Array.from({ length: 6 }).map((_, i) => (
                <NotificationSkeleton key={i} />
              ))}
            </div>
          )}
          {notifications?.meta?.total_amount !== notifications?.list.length && (
            <Button
              variant={'outline'}
              onClick={() => setAmount((prev) => prev + 10)}
              className='mx-auto my-2 justify-self-center'
            >
              <span className='whitespace-nowrap'>Ladda fler</span>{' '}
              {isFetching && <Icons.loader className='animate-spin' />}
            </Button>
          )}
        </TabsContent>
        <TabsContent
          className='grid max-h-[min(700px,calc(var(--window-keeper-height)-175px))] w-full overflow-y-auto px-4'
          value='read'
        >
          {!isLoading &&
            notifications?.list.map((notification) => (
              <NotificationComp
                key={notification.id}
                notification={notification}
                setSheetOpen={setSheetOpen}
              />
            ))}
          {isLoading && (
            <div className='grid gap-2'>
              {Array.from({ length: 6 }).map((_, i) => (
                <NotificationSkeleton key={i} />
              ))}
            </div>
          )}
          {!isLoading && !isFetching && notifications?.list.length === 0 && (
            <div className='flex flex-col items-center gap-2'>
              <BellSnoozeIcon className='size-16' />
              <p className='text-center font-medium'>
                Du har inga notifikationer. Passa på att ta en rast och sträck
                lite på benen! :)
              </p>
            </div>
          )}
          {notifications?.meta?.total_amount !== notifications?.list.length && (
            <Button
              disabled={isLoading || isFetching}
              variant={'outline'}
              onClick={() => setReadAmount((prev) => prev + 10)}
              className='mx-auto my-2 justify-self-center'
            >
              <span className='whitespace-nowrap'>Ladda fler</span>{' '}
              {isFetching && <Icons.loader className='animate-spin' />}
            </Button>
          )}
        </TabsContent>
        <TabsContent
          className='grid max-h-[min(700px,calc(var(--window-keeper-height)-175px))] w-full overflow-y-auto px-4'
          value='unread'
        >
          {!isLoading &&
            notifications?.list.map((notification) => (
              <NotificationComp
                key={notification.id}
                notification={notification}
                setSheetOpen={setSheetOpen}
              />
            ))}
          {!isLoading && !isFetching && notifications?.list.length === 0 && (
            <div className='flex flex-col items-center gap-2'>
              <BellSnoozeIcon className='size-16' />
              <p className='text-center font-medium'>
                Du har inga olästa notifikationer. Passa på att ta en rast och
                sträck lite på benen! :)
              </p>
            </div>
          )}
          {isLoading && (
            <div className='grid gap-2'>
              {Array.from({ length: 6 }).map((_, i) => (
                <NotificationSkeleton key={i} />
              ))}
            </div>
          )}
          {notifications?.meta?.total_amount !== notifications?.list.length && (
            <Button
              disabled={isLoading || isFetching}
              variant={'outline'}
              onClick={() => setUnreadAmount((prev) => prev + 10)}
              className='mx-auto my-2 justify-self-center'
            >
              <span className='whitespace-nowrap'>Ladda fler</span>{' '}
              {isFetching && <Icons.loader className='animate-spin' />}
            </Button>
          )}
        </TabsContent>
      </Tabs>
    </>
  );
}

function NotificationSkeleton() {
  return (
    <Skeleton className='flex w-full items-start justify-between rounded-md bg-muted/40 p-2'>
      <div className='grid gap-2'>
        <Skeleton className='h-4 w-56' />
        <Skeleton className='h-3 w-40' />
        <Skeleton className='h-2 w-36' />
      </div>
    </Skeleton>
  );
}
