import { addGlobalFilters } from '@/lib/api/globalFilter';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '../ui/dialog';
import { Input } from '../ui/input';
import FilterCompanies from './companies';
import FilteredItems from './filtered-items';
import FilterRealEstates from './real-estates';
import FilterSegments from './segments';
import TabBar from './tab-bar';

import { useToast } from '@/hooks/useToast';
import { AnimatePresence } from 'framer-motion';
import { useQueryState } from 'nuqs';
import { useDebounceCallback } from 'usehooks-ts';
import FilterExternalClients from './external-clients';

export type TabBarStates =
  | 'realestates'
  | 'segments'
  | 'externalclients'
  | 'companies';
export type GlobalFilterItem = {
  type:
    | 'global_realestates'
    | 'global_segments'
    | 'filter_external_clients'
    | 'global_companies';
  id: string;
  displayString: string;
};

export default function GlobalFilterModal({
  close,
  cachedFilters,
}: {
  close: () => void;
  cachedFilters: GlobalFilterItem[];
}) {
  const qc = useQueryClient();
  const { addToast } = useToast();
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState<TabBarStates>('segments');
  const [filteredItems, setFilteredItems] = useState<GlobalFilterItem[]>([]);
  const debouncedSearch = useDebounceCallback(setSearchValue, 300);
  const [filter, setFilter] = useQueryState('filter');
  useEffect(() => {
    setFilteredItems(cachedFilters);
  }, [setFilteredItems, cachedFilters]);

  const toggleFilteredItem = useCallback(
    (item: GlobalFilterItem) => {
      let runToast = false;

      setFilteredItems((prev) => {
        if (prev.find((p) => p.id === item.id && p.type === item.type)) {
          return prev.filter(
            (p) => !(p.id === item.id && p.type === item.type)
          );
        }
        if (item.type === 'global_segments') {
          const prevWithSegmentsOnly = prev.filter(
            (p) => p.type === 'global_segments'
          );
          runToast = prevWithSegmentsOnly.length < prev.length;

          return [...prevWithSegmentsOnly, item];
        }
        if (prev.find((p) => p.type === 'global_segments')) {
          const prevWithoutSegments = prev.filter(
            (p) => p.type !== 'global_segments'
          );
          runToast = true;

          return [...prevWithoutSegments, item];
        }
        return [...prev, item];
      });

      if (runToast) {
        addToast({
          title: 'Filtering rensad',
          message:
            'Det är inte möjligt att kombinera "Segment" med andra filter',
          type: 'info',
        });
      }
    },
    [addToast]
  );

  const onSave = async () => {
    await addGlobalFilters(filteredItems);
    if (filter) {
      setFilter(null);
    }
    qc.invalidateQueries();

    addToast({
      title: 'Filtreringen uppdaterades',
      message: 'Hämtar om listor...',
      type: 'info',
    });
    close();
  };

  return (
    <Dialog modal open onOpenChange={close}>
      <DialogContent className='flex min-w-[800px] flex-col gap-0 p-0'>
        <DialogTitle className='sr-only'>Filtrera i systemet</DialogTitle>
        <DialogDescription className='sr-only'>
          Filtrera systemet på bolag, fastighet, segment och uppdragsgivare.
        </DialogDescription>
        <div className='flex items-center justify-between gap-3 border-b px-4 py-2'>
          <div className='relative flex flex-1 items-center'>
            <MagnifyingGlassIcon className='pointer-events-none absolute left-2 my-auto size-4 text-foreground' />
            <Input
              className='rounded-sm pl-7 shadow-none'
              onChange={(e) => debouncedSearch(e.target.value)}
              placeholder='Sök...'
            />
          </div>
          <TabBar
            {...{
              selectedTab,
              setSelectedTab,
            }}
          />
        </div>

        <AnimatePresence>
          {filteredItems.length && (
            <FilteredItems
              items={filteredItems}
              {...{
                toggleFilteredItem,
                clearAll: () => {
                  setFilteredItems([]);
                },
              }}
            />
          )}
        </AnimatePresence>

        <div className='relative max-h-[450px] min-h-[450px] overflow-y-auto'>
          {selectedTab === 'externalclients' && (
            <FilterExternalClients
              search={searchValue}
              onToggle={(id: string, displayString: string) =>
                toggleFilteredItem({
                  id,
                  type: 'filter_external_clients',
                  displayString,
                })
              }
              {...{ filteredItems }}
            />
          )}
          {selectedTab === 'companies' && (
            <FilterCompanies
              searchValue={searchValue}
              onToggle={(id: string, displayString: string) =>
                toggleFilteredItem({
                  id,
                  type: 'global_companies',
                  displayString,
                })
              }
              {...{ filteredItems }}
            />
          )}
          {selectedTab === 'segments' && (
            <FilterSegments
              search={searchValue}
              onToggle={(id: string, displayString: string) =>
                toggleFilteredItem({
                  id,
                  type: 'global_segments',
                  displayString,
                })
              }
              {...{ filteredItems }}
            />
          )}
          {selectedTab === 'realestates' && (
            <FilterRealEstates
              search={searchValue}
              onToggle={(id: string, displayString: string) =>
                toggleFilteredItem({
                  id,
                  type: 'global_realestates',
                  displayString,
                })
              }
              {...{ filteredItems }}
            />
          )}
        </div>

        <div className='flex items-center justify-end border-t px-4 py-2'>
          <Button onClick={onSave} size='sm'>
            Spara
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
