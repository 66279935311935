import { BASE_BACKEND_URL } from '@/requests/constants';
import { getHeaders } from '@/requests/headers';
import { getCookie } from 'cookies-next';
import { DateTime } from 'luxon';

import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

type FailedInvoiceIds = Array<{ object_id: string }>;
type CountResponse = { count: number };

export const useFailedInvoices = <T extends boolean = false>(
  countOnly?: T
): UseQueryResult<T extends true ? CountResponse : FailedInvoiceIds, Error> => {
  return useQuery({
    queryKey: ['failedInvoices', countOnly],
    queryFn: async () => {
      const organizationId = getCookie('organization_id');
      if (!organizationId) {
        throw new Error('Organization ID is not available');
      }

      const params = {
        organization: organizationId,
        content_type: 'accounting.invoice',
        start_time: DateTime.now().minus({ month: 1 }).toISODate(),
        end_time: DateTime.now().toISODate(),
        event_identifier: 'accounting.invoice.invoiceattested',
      };

      const { headers } = await getHeaders();
      const response = await fetch(
        `${BASE_BACKEND_URL}/communication/failed_notification_deliveries/?` +
          new URLSearchParams(params).toString(),
        {
          method: 'GET',
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch failed deliveries');
      }
      const data = (await response.json()) as FailedInvoiceIds;

      if (!countOnly) {
        return data;
      }
      return {
        count: data.length,
      };
    },
  });
};
