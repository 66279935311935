'use client';
import { getCurrentGlobalFilters } from '@/lib/api/globalFilter';
import { ChevronUpDownIcon } from '@heroicons/react/16/solid';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { GlobalFilterItem } from '../../global-filter/global-filter-modal';
import GlobalFilterModal from '../../global-filter/global-filter-modal';

import { cn } from '@/lib/cn';
import { getList } from '@/requests/api/get-list';
import type {
  ExternalClient,
  RealEstate,
  Segment,
} from '@pigello/pigello-matrix';
import {
  CompanyConfig,
  ExternalClientConfig,
  RealEstateConfig,
  SegmentConfig,
} from '@pigello/pigello-matrix';
import useResizeObserver from 'use-resize-observer';

export function SegmentSelector() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [currentFilters, setCurrentFilters] = useState<GlobalFilterItem[]>([]);

  const calculateNameWidth = () => {
    const totalWidth = btnRef.current?.offsetWidth ?? 0;
    const padding = 50;

    const nameWidth = totalWidth - padding;

    setMaxNameWidth(nameWidth);
  };

  const btnRef = useRef<HTMLButtonElement>(null);

  const { ref: textRef } = useResizeObserver<HTMLDivElement>({
    onResize: calculateNameWidth,
  });

  const [maxNameWidth, setMaxNameWidth] = useState(0);

  useLayoutEffect(() => {
    calculateNameWidth();
  }, []);

  const getCachedFilters = async () => {
    const savedFilters = await getCurrentGlobalFilters();

    const realEstateIds = savedFilters.global_realestates;
    const segmentIds = savedFilters.global_segments;
    const externalClientIds = savedFilters.filter_external_clients;
    const companyIds = savedFilters.global_companies;

    if (
      !realEstateIds?.length &&
      !segmentIds?.length &&
      !externalClientIds?.length &&
      !companyIds?.length
    ) {
      setLoading(false);
      setCurrentFilters([]);
      return;
    }

    const newFilters: GlobalFilterItem[] = [];

    if (realEstateIds?.length) {
      const realEstates = await getList<RealEstate>({
        modelName: RealEstateConfig.modelName,
        queryParams: {
          skipGlobalFilters: true,
          filters: {
            id: { __in: realEstateIds.join(',') },
          },
        },
      });
      realEstates.list.forEach((re) =>
        newFilters.push({
          id: re.id as string,
          type: 'global_realestates',
          displayString: re.name,
        })
      );
    }

    if (segmentIds?.length) {
      const segments = await getList<Segment>({
        modelName: SegmentConfig.modelName,
        queryParams: {
          filters: {
            id: { __in: segmentIds.join(',') },
          },
        },
      });

      segments.list.forEach((re) =>
        newFilters.push({
          id: re.id as string,
          type: 'global_segments',
          displayString: re.name,
        })
      );
    }

    if (externalClientIds.length) {
      const externalClients = await getList<ExternalClient>({
        modelName: ExternalClientConfig.modelName,
        queryParams: {
          filters: {
            id: {
              __in: externalClientIds.join(','),
            },
          },
        },
      });

      externalClients.list.forEach((re) =>
        newFilters.push({
          id: re.id as string,
          type: 'filter_external_clients',
          displayString: re.name,
        })
      );
    }

    if (companyIds?.length) {
      const companies = await getList<Segment>({
        modelName: CompanyConfig.modelName,
        queryParams: {
          filters: {
            id: { __in: companyIds.join(',') },
          },
        },
      });

      companies.list.forEach((re) =>
        newFilters.push({
          id: re.id as string,
          type: 'global_companies',
          displayString: re.name,
        })
      );
    }

    setCurrentFilters(newFilters);
    setLoading(false);
  };

  useEffect(() => {
    getCachedFilters();
  }, []);

  const renderFiltered = () => {
    if (!currentFilters.length) return 'Visar hela beståndet';

    const str = `${currentFilters[0].displayString}`;

    const additional = currentFilters.length - 1;

    if (additional > 0) {
      return (
        <div className='flex flex-row items-center'>
          <div className='mr-1 truncate'>{str}</div>
          <div className='rounded-sm bg-primary p-1 text-[10px] text-primary-foreground'>
            +{additional}
          </div>
        </div>
      );
    }

    return str;
  };

  const renderHeader = () => {
    if (!currentFilters.length)
      return <div className='text-xs leading-none'>Filtrera systemet</div>;

    const hasExteranClientFilters = currentFilters.find(
      (f) => f.type === 'filter_external_clients'
    );
    const hasSegmentFilters = currentFilters.find(
      (f) => f.type === 'global_segments'
    );
    const hasRealEstateFilters = currentFilters.find(
      (f) => f.type === 'global_realestates'
    );
    const hasCompanyFilters = currentFilters.find(
      (f) => f.type === 'global_companies'
    );

    const filterTypeCount = [
      hasExteranClientFilters,
      hasSegmentFilters,
      hasRealEstateFilters,
      hasCompanyFilters,
    ].filter((f) => f).length;

    return (
      <div className='text-xs leading-none'>
        {filterTypeCount === 3
          ? 'Uppdragsgivare, bolag & fastighet'
          : filterTypeCount === 2
            ? hasExteranClientFilters
              ? 'Uppdragsgivare & ' +
                (hasRealEstateFilters ? 'fastighet' : 'bolag')
              : 'Bolag & fastighet'
            : hasExteranClientFilters
              ? 'Uppdragsgivare'
              : hasSegmentFilters
                ? 'Segment'
                : hasRealEstateFilters
                  ? 'Fastighet'
                  : 'Bolag'}
      </div>
    );
  };

  return (
    <>
      {open && !loading && (
        <GlobalFilterModal
          cachedFilters={currentFilters}
          close={() => {
            setOpen(false);
            getCachedFilters();
          }}
        />
      )}
      <button
        ref={btnRef}
        type='button'
        onClick={() => setOpen(true)}
        className={cn(
          'flex w-full items-center justify-between rounded border bg-background p-2 shadow-sm ring-offset-background transition-colors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          {
            'border-primary/50 bg-accent/70': currentFilters?.length > 0,
          }
        )}
      >
        <div className='flex flex-col text-start'>
          {renderHeader()}
          <div
            style={{ maxWidth: maxNameWidth }}
            ref={textRef}
            className='mt-1  text-xs font-medium  leading-none'
          >
            {loading ? 'Laddar...' : renderFiltered()}
          </div>
        </div>

        <ChevronUpDownIcon width={20} />
      </button>
    </>
  );
}
