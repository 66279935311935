 import type { Segment } from '@pigello/pigello-matrix';


import { useGetInstance, useGetList } from '@/requests/hooks';

import type {
  useGetGenericInstanceProps,
  useGetGenericListProps,
} from '@/requests/types';


export const useGetSegment = (
  options: useGetGenericInstanceProps<Segment>
) => {
  return useGetInstance<Segment>({
    ...options,
    modelName: 'segment'
  });
};

export const useGetSegmentList = (
  options: useGetGenericListProps<Segment>
) => {
  return useGetList<Segment>({
    ...options,
    modelName: 'segment'
  });
};
