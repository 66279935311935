 import type { Company } from '@pigello/pigello-matrix';


import { useGetInstance, useGetList } from '@/requests/hooks';

import type {
  useGetGenericInstanceProps,
  useGetGenericListProps,
} from '@/requests/types';


export const useGetCompany = (
  options: useGetGenericInstanceProps<Company>
) => {
  return useGetInstance<Company>({
    ...options,
    modelName: 'company'
  });
};

export const useGetCompanyList = (
  options: useGetGenericListProps<Company>
) => {
  return useGetList<Company>({
    ...options,
    modelName: 'company'
  });
};
