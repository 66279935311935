'use client';
import type { SubMenuRoute } from '@/app/dashboard/data';
import { useManyPerms, usePerms } from '@/hooks/usePerms';
import { cn } from '@/lib/cn';
import { raise } from '@/lib/utils';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../ui/accordion';
import NotificationIndicator from './notification-indicator';

export default function SideBarMenuItem({
  route,
  notifications,
  noActiveIndication,
}: {
  route: SubMenuRoute;
  notifications?: { [key: string]: number };
  noActiveIndication?: boolean;
}) {
  const pathname = usePathname();
  const modelName = useMemo(() => route.modelName, [route]);
  const { canView } = usePerms(modelName);
  const modelNames = route.modelNames || [];
  const { perms } = useManyPerms(modelNames);
  const isNested = route.subMenus && route.subMenus?.length > 0;
  const hasNoPerms = isNested
    ? perms.every((perm) => !perm.canView) && modelNames.length > 0 // if  nested, one perm must match
    : perms.some((perm) => !perm.canView) && modelNames.length > 0; // else ,all perms

  if (hasNoPerms) return null;
  if ((!canView && route.modelName) || route.hidden) return null;
  if (isNested) {
    return (
      <AccordionItem
        className='border-none'
        value={route.title}
        key={route.title}
      >
        <AccordionTrigger className='my-0.5 flex w-full items-center justify-between gap-2 rounded px-2 py-1 text-left text-sm  text-secondary-foreground transition-colors hover:bg-muted-foreground/10  hover:no-underline dark:text-foreground'>
          <div className='flex items-center gap-1'>
            {route.icon}
            <p className='shrink truncate font-normal text-secondary-foreground dark:text-foreground'>
              {route.title}
            </p>
          </div>
        </AccordionTrigger>
        <AccordionContent className='my-1 pb-0'>
          <ul>
            {route.subMenus?.map((subMenu) => (
              <SubMenuItem
                key={subMenu.title + subMenu.path}
                subMenu={subMenu}
              />
            ))}
          </ul>
        </AccordionContent>
      </AccordionItem>
    );
  }
  if (!route.path)
    raise(`SubMenu path is undefined in SidebarMenuItem ${route.title}`);

  const isActive =
    !noActiveIndication &&
    pathname.split('/').at(-1) === route?.path.split('/').at(-1);

  const notificationCount = notifications?.[route.path];

  return (
    <ul key={route.title}>
      <Link
        prefetch={false}
        aria-disabled={route.disabled}
        className={cn(
          route.disabled && 'pointer-events-none cursor-default opacity-50'
        )}
        href={route.path}
      >
        <li
          className={cn(
            'my-0.5 flex w-full items-center justify-between gap-2 truncate rounded px-2 py-1 text-left text-sm text-foreground transition-colors hover:bg-muted-foreground/10 ',
            isActive && 'bg-accent'
          )}
        >
          <div className='flex w-full items-center gap-1 transition-all duration-200 active:translate-x-0.5'>
            {route.icon}
            <p className='truncate text-foreground'>{route.title}</p>
            {notificationCount && notificationCount > 0 && (
              <NotificationIndicator count={notificationCount} />
            )}
          </div>
        </li>
      </Link>
    </ul>
  );
}

function SubMenuItem({ subMenu }: { subMenu: SubMenuRoute }) {
  const pathname = usePathname();

  const modelName = useMemo(() => subMenu.modelName, [subMenu]);
  const { canView } = usePerms(modelName);
  const modelNames = subMenu.modelNames || [];
  const { perms } = useManyPerms(modelNames);

  const hasNoPerms =
    perms.some((perm) => !perm.canView) && modelNames.length > 0; //,all perms

  if (hasNoPerms) return null;
  if ((!canView && subMenu.modelName) || subMenu.hidden) return null;

  if (!subMenu.path) raise('SubMenu path is undefined in SidebarMenuItem');
  const subIsActive = pathname.includes(subMenu.path);
  return (
    <Link
      prefetch={false}
      key={subMenu.title}
      className={cn(
        'w-full',
        subMenu.disabled && 'pointer-events-none opacity-50'
      )}
      href={subMenu.path}
    >
      <li className='my-0.5 truncate pl-5'>
        <div
          className={cn(
            'flex w-full shrink items-center gap-2 truncate rounded px-2 py-1 text-sm text-foreground transition-colors hover:bg-muted-foreground/10',
            subIsActive && 'bg-accent'
          )}
        >
          <div
            className={cn(
              'size-2 rounded-full bg-foreground/30',
              subIsActive && 'bg-primary'
            )}
          />
          {subMenu.title}
        </div>
      </li>
    </Link>
  );
}
